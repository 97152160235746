import http from "./http";
export default (entryId) => ({
  async index(filters) {
    return http
      .index("entries", filters)
      .then((json) => ({ entries: json }))
      .catch(async (e) => ({ entries: null, error: await e }));
  },
  async get() {
    return http
      .get("entries", entryId)
      .then((json) => ({ entry: json }))
      .catch(async (e) => ({ entry: null, error: await e }));
  },
  async create(data) {
    return http
      .post("entries", data)
      .then((json) => ({ entry: json }))
      .catch(async (e) => ({ entry: null, error: await e }));
  },
  async update(data) {
    return http
      .put("entries", entryId, data)
      .then((json) => ({ entry: json }))
      .catch(async (e) => ({ entry: null, error: await e }));
  },
  async delete() {
    return http
      .delete("entries", entryId)
      .then((json) => ({ entry: json }))
      .catch(async (e) => ({ entry: null, error: await e }));
  },
  Products: (productId) => ({
    async index(filters) {
      return http
        .index(`entries/${entryId}/products`, filters)
        .then((json) => ({ products: json }))
        .catch(async (e) => ({ products: null, error: await e }));
    },
    async get() {
      return http
        .get(`entries/${entryId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`entries/${entryId}/products`, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`entries/${entryId}/products`, productId, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`entries/${entryId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
  }),
  Observations: (obsId) => ({
    async index(filters) {
      return http
        .index(`entries/${entryId}/obs`, filters)
        .then((json) => ({ observations: json }))
        .catch(async (e) => ({ observations: null, error: await e }));
    },
    async get() {
      return http
        .get(`entries/${entryId}/obs`, obsId)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`entries/${entryId}/obs`, data)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`entries/${entryId}/obs`, obsId, data)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`entries/${entryId}/obs`, obsId)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
  }),
  Logs: () => ({
    async index() {
      return http
        .index(`entries/${entryId}/logs`)
        .then((json) => ({ logs: json }))
        .catch(async (e) => ({ logs: null, error: await e }));
    },
  }),
});
